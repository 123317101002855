import React, { useState } from 'react';
import {FaBars, FaTimes} from 'react-icons/fa';
import { motion } from "framer-motion";
import face from '../assets/logo.png';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import { Link } from 'react-scroll';

const NavBar = () => {
    const [nav, setNav] = useState(false);

    const links = [
        { id: 1, link: "home" },
        { id: 2, link: "about" },
        { id: 3, link: "portfolio" },
        { id: 4, link: "experience" },
        { id: 5, link: "contact" },
    ];
    const [selected, setSelected] = useState("home"); // Initialize selected with "home"

    return (
        <div className='flex px-4 justify-between items-center w-full h-20 text-white bg-black z-10 fixed'>
            <div className='flex items-center gap-2'>
                <img className='w-15 h-10' src={face} alt="" />
                <Link to='home' smooth duration={500}>
                    <h1 className='font-signature text-5xl cursor-pointer'>Chris</h1>
                </Link>
            </div>

            <ul className='hidden text-gray-400 md:flex items-center justify-center'>
                {links.map(({ id, link }) => (
                    <Link
                        to={link}
                        key={id}
                        smooth
                        duration={500}
                        spy={true}
                        onSetActive={() => setSelected(link)} // Update selected when the section becomes active
                    >
                        <Chip
                            text={link}
                            selected={selected === link}
                            setSelected={setSelected}
                            key={id}
                        />
                    </Link>
                ))}
            </ul>
            <div className='flex gap-2 mr-2 md:hidden'>
                    <a target='_blank' href="https://github.com/ChrisLoui">
                        <button><FaGithub/></button>
                    </a>
                    <a target='_blank' href="https://www.linkedin.com/in/chris-loui-canete-228118287/">
                        <button><FaLinkedin/></button>
                    </a>
            </div>

            <div onClick={() => setNav(!nav)} className='md:hidden cursor-pointer pr-4 z-10 text-gray-500 hover:text-white flex items-center justify-center'>
            
            <div className='flex gap-2 mr-2 md:hidden'>
            </div>
            <div className='hover:scale-110 duration-300'>
            {nav ? <FaTimes size={30}/> : <FaBars size={30}/>}
            </div>
            <div className='mr-[-25px] ml-1'>
            
            </div>
            </div>

            {nav && (
                <ul className='text-gray-300 space-y-6 capitalize flex flex-col items-center justify-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800'>
                {links.map(({id, link}) => (
                    <li className='cursor-pointer text-xl hover:scale-110 hover:text-white' key={id}>
                    <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>{link}</Link>
                    </li>
                ))}
              
            </ul>
            )}
        </div>
    );
};

const Chip = ({
    text,
    selected,
    setSelected,
}) => {
    return (
        <button
            onClick={() => setSelected(text)}
            className={`${
                selected
                    ? "text-white"
                    : "text-slate-300 hover:text-slate-200 hover:bg-slate-700"
                } text-sm transition-colors px-2.5 py-0.5 rounded-md relative`}
        >
            <span className="relative z-10 capitalize">{text}</span>
            {selected && (
                <motion.span
                    layoutId="pill-tab"
                    transition={{ type: "spring", duration: 0.5 }}
                    className="absolute inset-0 z-0 bg-gradient-to-r from-cyan-500 via-blue-500 to-blue-500 rounded-md"
                ></motion.span>
            )}
        </button>
    );
};

export default NavBar;
