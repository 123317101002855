import React from 'react'
import { FaLinkedin, FaGithub } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { BsFillPersonLinesFill } from 'react-icons/bs'


const Links = () => {

    const link = [
        {
            id: 1,
            child: (
                <>LinkedIn <FaLinkedin size={30}/></>
            ),
            href: 'https://www.linkedin.com/in/chris-loui-canete-228118287/',
            style: 'bg-blue-500 rounded-tr-md'
            
        },
        {
            id: 2,
            child: (
                <>Github <FaGithub size={30}/></>
            ),
            href: 'https://github.com/ChrisLoui',
            style: 'bg-black'
            
            
        },
        {
            id: 3,
            child: (
                <>Email <HiOutlineMail size={30}/></>
            ),
            href: 'mailto:cacanete@up.edu.ph',
            style: 'bg-red-500'
            
            
        },
        {
            id: 4,
            child: (
                <>Resume <BsFillPersonLinesFill size={30}/></>
            ),
            href:'/[Chris Canete] Resume - Iskolar ng bayan 2024.pdf' ,
            style: 'rounded-br-md bg-yellow-500',
            download: true,
            
        },
    ]

  return (
    <div className='flex flex-col top-[35%] left-0 fixed z-10'>
        <ul>
           {link.map(({id, child, href, style, download}) =>(
                        <li key={id} className='hidden lg:flex justify-between items-center w-40 h-14'>
                            <a href={href} className={"flex justify-between py-1 px-4 items-center w-full text-white ml-[-100px] hover:rounded-md hover:ml-[-10px] duration-300" +" "+ style}
                            download={download}
                            target='_blank'
                            rel='noreferrer'>
                                    {child} 
                            </a>
                        </li>
           ))}
        </ul>
    </div>
  )
}

export default Links