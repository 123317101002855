import React from 'react'
import ShuffleCards from './ShuffleCards'
import HoverSocials from './HoverSocials'




const Portfolio = () => {

    const port = [
        {
            id: 7,
            src: `/folderly.png`,
            href: "https://folderly.online/",
                     
        },
        {
            id:5,
            src: "bday.png",
            href:"https://npa.debmac.tech/",
            code: ''

        },
        {
            id:6,
            src: "netflix.png",
            href:"https://netflixy-clone-ab166.web.app/",
            code: 'https://github.com/ChrisLoui/netflixy/tree/main'

        },
        {
            id:2,
            src: "Spotwithsongs.png",
            href: "https://testspotify.debmac.tech/",
            code:'https://github.com/ChrisLoui/Spotify-Create',
            download:true,

        },
        {
            id: 8,
            src: "spotify.png",
            href: "https://testspotify.debmac.tech/",
            code: 'https://github.com/ChrisLoui/Spotify-Music'
                     
        },

        {
            id:3,
            src: "fb.png",
            code:'https://github.com/ChrisLoui/FB-UI-Duplicate'

        },
        {
            id:4,
            src: "portfolio.png",
            href:"https://chris.debmac.tech/#home",
            code: 'https://github.com/ChrisLoui/practice-website'

        },
        {
            id:1,
            src: "discord.png",
            code:"https://github.com/ChrisLoui/Discord-Practice",
            href:"https://chrisdiscord.debmac.tech/",

        },


        

        
    ] 
 

  return (
    <div name="portfolio" className='w-full bg-gradient-to-b text-white from-black via-gray-900 to-gray-900 px-4'>
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center w-full h-full '>
            <div className='pb-8'>
                <p className='text-5xl md:text-7xl font-bold underline pt-20'>Portfolio</p>
                <div className='flex items-center justify-center'>
                <p className='text-xs sm:text-md mt-2'>Check out my Projects</p>
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-5 sm:grid-cols-2 gap-4'>
            {
                port.map(({id, src, href, download,code })=>(
                    <div  key={id} className='shadow-md shadow-gray-700 rounded-lg duration-200'>
                <img src={src}
                 alt="dsfsdf" className='rounded-md'/>
                <div className='flex text-gray-500 items-center justify-center px-2'>
                    <button className='w-1/2 px-6 py-3 m-4 sm:text-sm  text-gray-400 shadow-xl hover:scale-105 hover:text-white  duration-200'>
                        <a href={href} target='_blank'>Demo</a></button>
                    <button className='w-1/2 sm:text-sm text-gray-400 px-6 py-3 m-4 hover:scale-105 hover:text-white duration-200  shadow-xl'
                    ><a
                     
                     href={code}
                    target='_blank'
                    rel='noreferrer'>Code</a></button>
                </div>
                </div>

                ))
            }
            </div>
            <div className='w-full hidden sm:block'><ShuffleCards/></div>  
        <div>         
        </div>
        </div>
    </div>
  )
}

export default Portfolio