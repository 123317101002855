import { motion } from "framer-motion";
import { useRef, useState } from "react";

const ShuffleCards = () => {
  const [order, setOrder] = useState([
    "front", "middle", "back", "fourth", "fifth",
    "sixth", "seventh", "eighth", "ninth", "tenth"
  ]);

  const handleShuffle = () => {
    const orderCopy = [...order];
    orderCopy.unshift(orderCopy.pop());
    setOrder(orderCopy);
  };

  return (
    
    <div className="grid place-content-center overflow-hidden py-24 text-slate-50">
      <div className="relative -ml-[100px] h-[450px] w-[350px] md:-ml-[175px]">
        <Card
          imgUrl="/folderly.png"
          testimonial= "Landing page for Folderly. Folderly is a Flutter-based application designed to simplify and enhance the academic experience for students with AI Technology."
          author="Co-Founder"
          handleShuffle={handleShuffle}
          position={order[0]}
        />
        <Card
        imgUrl="/bday.png"
        testimonial="Co-Created our very own compiler. It offers dynamic syntax for flexible coding, a compiler certification tool, and a time complexity calculator to optimize algorithm efficiency."
        uthor="Co-Creator"

          handleShuffle={handleShuffle}
          position={order[1]}
        />
        <Card
          imgUrl="/netflix.png"
          testimonial="Implemented Signin|Login using Firebase and integrated liked movies that stores in Firebase."
          author="Personal Project"
          handleShuffle={handleShuffle}
          position={order[2]}
        />
        <Card
        imgUrl="/Spotwithsongs.png"
        testimonial="Practice project wherein I duplicated spotify ui and implemented playable songs."
        author="Personal Project"
          handleShuffle={handleShuffle}
          position={order[3]}
        />
        <Card
          imgUrl="/spotify.png"
          testimonial="Practiced Tailwind CSS by duplicating Spotify UI."
          author="Personal Project"
          handleShuffle={handleShuffle}
          position={order[4]}
        />
        <Card
                  imgUrl="/fb.png"
                  testimonial="Practiced Tailwind CSS by duplicating Facebook UI."
                  author="Personal Project"

          handleShuffle={handleShuffle}
          position={order[5]}
        />
        <Card
          imgUrl="/portfolio.png"
          testimonial="First Ever website, made using HTML, CSS, and Javascript."
          author="Practice Website"
          handleShuffle={handleShuffle}
          position={order[6]}
        />
        <Card
            imgUrl="/discord.png"
                  testimonial="Practiced Tailwind CSS by duplicating Discord UI."
                  author="Personal Project"

          handleShuffle={handleShuffle}
          position={order[7]}
        />
        <Card
          imgUrl="/"
          testimonial="Entern is project we made for Komsai 2024 Hackathon. It is to connect Interns and Clients."
          author="ENtern"
          handleShuffle={handleShuffle}
          position={order[8]}
        />
        <Card
          imgUrl="/"
          testimonial="Placeholder for future project"
          author="Test"
          handleShuffle={handleShuffle}
          position={order[9]}
        />
      </div>
    </div>
  );
};

const Card = ({ handleShuffle, testimonial, position, imgUrl, author }) => {
  const mousePosRef = useRef(0);

  const onDragStart = (e) => {
    mousePosRef.current = e.clientX;
  };

  const onDragEnd = (e) => {
    const diff = mousePosRef.current - e.clientX;

    if (diff > 150) {
      handleShuffle();
    }

    mousePosRef.current = 0;
  };

  const positions = {
    front: { x: "-30%", rotateZ: "0deg", zIndex: "9" },
    middle: { x: "-20%", rotateZ: "0deg", zIndex: "8" },
    back: { x: "-10%", rotateZ: "0deg", zIndex: "7" },
    fourth: { x: "0%", rotateZ: "0deg", zIndex: "6" },
    fifth: { x: "10%", rotateZ: "0deg", zIndex: "5" },
    sixth: { x: "20%", rotateZ: "0deg", zIndex: "4" },
    seventh: { x: "30%", rotateZ: "0deg", zIndex: "3" },
    eighth: { x: "40%", rotateZ: "0deg", zIndex: "2" },
    ninth: { x: "50%", rotateZ: "0deg", zIndex: "1" },
    tenth: { x: "60%", rotateZ: "0deg", zIndex: "0" },
  };

  const { x, rotateZ, zIndex } = positions[position] || positions.back;
  const draggable = position === "front";

  return (
    <motion.div
      style={{ zIndex }}
      animate={{ rotate: rotateZ, x }}
      drag
      dragElastic={0.35}
      dragListener={draggable}
      dragConstraints={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      transition={{ duration: 0.35 }}
      className={`absolute left-0 top-0 grid h-[450px] w-[300px]  sm:w-[350px] select-none place-content-center space-y-6 rounded-2xl border-2 border-slate-700 bg-slate-800/20 p-6 shadow-xl backdrop-blur-md ${
        draggable ? "cursor-grab active:cursor-grabbing" : ""
      }`}
    >
      <img
        src={imgUrl}
        alt={`Image of ${author}`}
        className="pointer-events-none mx-auto h-40 w-full border-2 border-slate-700 bg-slate-200 object-cover"
      />
      <span className="text-center text-lg italic text-slate-400">
        {testimonial}
      </span>
      <span className="text-center text-sm font-medium text-indigo-400">
        {author}
      </span>
    </motion.div>
  );
};

export default ShuffleCards;
