import React from 'react'
import Example from './SpinningLogos'


const Experience = () => {

    const exp = [
       
    ]
  return (
    <div name="experience" className='w-full h-screen bg-gradient-to-b from-gray-900 via-black to-black flex flex-col items-center justify-center py-4'>
        <div className='max-w-screen-lg mx-auto p-4 w-full h-full flex flex-col justify-center'>
            <div className='flex flex-col items-center justify-center'>
            <h2 className='text-5xl text-white font-bold md:text-7xl mt-4 underline'>Experience</h2>
            <Example/>
            </div>
        </div>
    </div>
  )
}

export default Experience