import About from "./components/About";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Home from "./components/Home";
import Links from "./components/Links";
import NavBar from "./components/NavBar";
import Portfolio from "./components/Portfolio";
import SocialIcons from "./components/SocialIcons";





function App() {
  return (
    <div>
      <NavBar />
      < Home />
      <About />
      <Links />
      <Portfolio />
      <Experience />
      <Contact />
    </div>

  );
}

export default App;
