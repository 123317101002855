import React from 'react'
import { useState } from 'react';
import hero from '../assets/hero.png'
import real1 from '../assets/real1.png'
import real2 from '../assets/real2.png'
import {Link} from 'react-scroll'
import WetPaintButton from './WetPaintButton';

import { MdKeyboardArrowRight } from "react-icons/md";


const Home = () => {

    const [src, setSrc] = useState(hero); // Initialize src state with initial image

  // Function to handle click event
  const handleClick = () => {
    const images = [hero, real1, real2];
    // Find the index of the current image in the array
    const currentIndex = images.indexOf(src);
    // Calculate the index of the next image
    const nextIndex = (currentIndex + 1) % images.length;
    // Change src to the next image after a delay
    setTimeout(() => {
      setSrc(images[nextIndex]);
    }, 300); 
  };

  return (
    <div id='home' name="home" className='h-screen w-full bg-gradient-to-b from-black to-gray-900'>
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
            <div className='text-white flex flex-col justify-center h-full'>
                <h2 className="font-bold text-4xl md:text-6xl max-w-md pt-20">
                    I'm a Full Stack Developer
                </h2>
                <h3 className='text-2xl font-bold md:text-4xl max-w-md'>/Project Manager</h3>

                <p className=' text-gray-400 py-4 max-w-sm '>
                    Im a Junior Computer Science student in UPCebu.
                    Co-founder and project manager of <button className='underline text-blue-400'><a href="https://folderly.online/" target='_blank'>Folderly</a></button>
                    . Currently, I love to work on duplicating and creating web applications using 
                    React Js, Tailwind, Next Js, with Firebase as the backend.
                </p>
                <div>
                    <Link to='portfolio' smooth duration={500} className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 via-blue-500 to-blue-500 cursor-pointer'>
                        Porfolio
                        <span className='group-hover:rotate-90 duration-300'>
                        <MdKeyboardArrowRight size={25} className='ml-1'/>
                        </span> 
                    </Link>
                </div>
            </div>
            <div>

            <img
      className='cursor-pointer w-full h-80 rounded-xl duration-300 max-w-md shadow-md shadow-yellow-300'
      src={src}
      alt=""
      onClick={handleClick} // Attach click event handler
    />
    
              <a href='/[Chris Canete] Resume - Iskolar ng bayan 2024.pdf' download className='text-white w-full capitalize flex justify-center mt-4'>
              <WetPaintButton/>
              </a>
            </div>
        </div>
    </div>
  )
}

export default Home