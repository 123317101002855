import React from 'react'
import FloatingPhone from './FloatingPhone'

const About = () => {
  return (
    <div name="about">
        <div id='about' className='h-screen space-y-4 flex flex-col justify-center items-center text-white bg-gradient-to-b from-gray-900 via-black to-black px-4'>
            <p className='text-5xl md:text-7xl font-bold underline'>About</p>
            <div className='text-sm md:text-md'>
                <p className='max-w-lg text-justify mt-1'>
                Co-Founder/Project Manager/Web Developer at "Folderly". I drove my team to deliver timely iterations and prioritize features based on user feedback, ensuring "Folderly" meets market demands and user expectations.
                </p>
                <p className='max-w-lg text-justify mt-4'>
                
                </p>
            </div>
            <div><FloatingPhone/></div>
        </div>
    </div>
  )
}

export default About